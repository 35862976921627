<template>
    <div class="container">
        <slot name="registrationType"></slot>
        <h1 class="mt-0 mb-5">{{$t('documents.title')}}</h1>

            <h5 class="text-aspena-blue">
                {{(type === 'company') ? $t('documents.agencySubtitle') : $t('documents.subtitle')}}
            </h5>
            <ul class="mb-5">
                <li v-if="type === 'person'">{{$t('documents.cv')}}</li>
                <li v-else>{{$t('documents.companyPresentation')}}</li>
                <li v-if="type === 'person'">{{$t('documents.educationDocuments')}}</li>
                <li v-if="type === 'person'">{{$t('documents.selfemploymentRegistration')}}</li>
                <li>{{$t('documents.vatRegistration')}}</li>
                <li v-if="type === 'company'">{{$t('documents.companyRegistration')}}</li>
                <li v-if="type === 'person'">{{$t('documents.certificationDocuments')}}</li>
                <li v-else>{{$t('documents.companyCertificationDocuments')}}</li>
                <li>{{$t('documents.insuranceDocuments')}}</li>
            </ul>
            <uploader-box
                class="mb-5"
                :uploader-name="'file'"
                :url="url"
                :multi="true"
                @uploaded="onUploaded"
            />
            <documents-list
                v-model="documents"
                :show-references="false"
                @types-valid="onTypesValid"
            >
                <template #errors>
                    <div v-show="localDocuments.length === 0" class="alert alert-danger text-center">
                        {{$t('references.uploadAtLeastOneFile')}}
                    </div>
                </template>
            </documents-list>

            <div class="mt-4">
                <form-input-textarea
                    :label="$t('documents.note')"
                    :hint="$t('documents.noteHint')"
                    :id="'note'"
                    v-model="note"
                />
            </div>

            <div class="mt-4 text-right">
                <aspena-btn
                    class="mr-3"
                    variant="aspena-blue"
                    @click.prevent="onCancel"
                >
                    {{$t('documents.cancel')}}
                </aspena-btn>

                <aspena-btn
                    class="mr-3"
                    variant="aspena-orange"
                    @click.prevent="onSubmit"
                    :disabled="!isValid"
                >
                    {{$t('documents.submit')}}
                </aspena-btn>
            </div>
        </div>
</template>

<script>
import { DocumentsList, Document } from '../../DTO'
import UploaderBox from './Components/UploaderBox.vue'
import DocumentsListComponent from './Components/DocumentsList.vue'

export default {
    name: 'DocumentsPage',
    components: {
        UploaderBox,
        DocumentsList: DocumentsListComponent
    },
    computed: {
        documentsList () {
            return this.localDocuments.filter(item => !item.tempId)
        },
        typesValid () {
            for (const item of this.documentsList) {
                if (!item.type) {
                    return false
                }
            }
            return true
        },
        isValid () {
            return this.localDocuments.length > 0 && this.typesValid && this.isCVUploaded && this.isCompanyRegistrationUploaded
        },
        isCVUploaded () {
            return (this.type === 'person') ? (this.localDocuments.filter(item => item.type === 'cv').length >= 1) : true
        },
        isCompanyRegistrationUploaded () {
            return (this.type !== 'person') ? (this.localDocuments.filter(item => item.type === 'companyRegistration').length >= 1) : true
        },
        url () {
            return this.$api.uploads.uploadUrl
        },
        type () {
            return this.$store.state.userData.basicData.type
        },
        localDocuments () {
            return this.documents.filter(item => item.type !== 'referenceDocuments')
        },
        documents: {
            get () {
                const state = this.$store.state.userData
                if (state.documentsList && state.documentsList.documents) {
                    return state.documentsList.documents
                }
                return []
            },
            set (value) {
                const state = this.$store.state.userData.documentsList || { documents: [] }
                const data = new DocumentsList({ ...state, documents: value })
                this.$store.commit('mergeData', data)
            }
        },
        note: {
            get () {
                const state = this.$store.state.userData
                if (state.documentsList && state.documentsList.note) {
                    return state.documentsList.note
                }
                return null
            },
            set (value) {
                const state = this.$store.state.userData.documentsList || { note: null, documents: [] }
                const data = new DocumentsList({ ...state, note: value })
                this.$store.commit('mergeData', data)
            }
        }
    },
    mounted () {
        this.$nextTick(() => {
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 200)
        })
    },
    methods: {
        onTypesValid (value) {
            this.typesValid = value === true
        },
        onUploaded (data) {
            const documents = this.documents
            if (data.serverId) {
                const document = JSON.parse(data.serverId)
                if (document.id) {
                    documents.push(new Document(document))
                    this.documents = documents
                }
            }
        },
        onSubmit () {
            if (this.localDocuments.length === 0) {
                return
            }
            this.$router.push({ name: 'vop' })
        },
        onCancel () {
            this.$router.push({ name: (this.type === 'person') ? 'references' : 'workHours' })
        }
    }
}
</script>

<style lang="scss">
.references-list .list-group-item {
    letter-spacing: unset;
    font-weight: 400;
    text-transform: none;
}
</style>
